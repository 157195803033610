import React from 'react'
import { PageProps } from 'gatsby'

import { Hero } from '../components/Hero'
import { Footer } from '../components/Footer'
import { Layout } from '../components/Layout'

export default function Home(props: PageProps) {
  return (
    <Layout>
      <Hero />
      <main id="main">
        <div id="contact" className="p-8">
          <div className="max-w-screen-sm mx-auto space-y-2">
            <h1 className="font-display font-bold text-3xl">Page Not Found</h1>
            <p>
              Please go to the{' '}
              <a
                href="/"
                className="text-neutral-alpha hover:underline hover:text-neutral-alpha-highlight"
              >
                home page
              </a>
              .
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}
